.ButtonSection {
  display: flex;
  align-items: center;
  justify-content: space-evenly;  
  .transition_btn {
    width: 60px;
    height: 60px;
    border: 1px solid #c07203;
    border-radius: 50%;
    margin: 2px;
    background: #f0a309;  
  }
}

.left-column-wrapper {  
  width: 100%;
  border: 1px solid #aaa8a8;
  @include themify($themes) {
    background-color: themed("backgroundColor");
  }
}