
.midi_btn {
  width: 60px;
  height: 60px;
  border: 1px solid #F2A509;
  border-radius: 50%;
  margin: 2px;
  background: #f0a309;
}
.audio_btn {
  width: 60px;
  height: 60px;
  border: 1px solid #006caf;
  border-radius: 50%;
  margin: 2px;
  background: #1aa0df;
}
.lfo_btn {
  width: 60px;
  height: 60px;
  border: 1px solid #D89E10;
  border-radius: 50%;
  margin: 2px;
  background: #FFE6CC;
}
.patch_btn {
  width: 60px;
  height: 60px;
  border: 1px solid #7394C2;
  border-radius: 50%;
  margin: 2px;
  background: #DAE8FC;
}
