.library_body_container {
  padding: 2rem;
  background-color: #f2f2f2;
  min-height: 100%;
  height: 100%;
}

.library_body_card {
  padding: 2rem;
  background-color: #ffffff;
  min-height: 500px
}

.interactive-piano__piano-container {
  display: inline-flex;
  box-sizing: border-box;
  border-top: 5px solid #dddddd;
  position: relative;
  transform: rotate(-90deg);
  margin: 200% 300% 200% -200%;
  background-color: #dddddd;
  padding: 0px 10px 10px 10px;
}

.piano-midi-details {
  display: inline-flex;
  border-top: 5px solid #dddddd;
  background-color: #dddddd;
  padding: 0px 10px 10px 10px;
  justify-content: center;
}

.interactive-piano__piano-container::after {
  content: '';
  width: 100%;
  height: 5px;
  position: absolute;
  transform: rotate(90deg);
}

.interactive-piano__accidental-key__wrapper {
  position: relative;
  width: 0;
}

.interactive-piano__accidental-key {
  position: absolute;
  transform: translateX(-50%);
  cursor: pointer;
  background: #444;
  width: 22px;
  height: 64px;
  border-radius: 0px 0px 3px 3px;
  border: 3px solid #444;
  border-top: none;
  box-sizing: border-box;
  padding: 5px;
  outline: none;
  color: #dbdbdb;
}

.interactive-piano__accidental-key--playing {
  background-color: #91e537;
  border: 3px solid #86db2b;
  border-top: none;
  color: #fff;
}

.interactive-piano__natural-key {
  cursor: pointer;
  background: #fafafa;
  width: 30px;
  height: 96px;
  margin: 0 2px;
  border-radius: 0 0 3px 3px;
  border: none;
  padding: 10px;
  outline: none;
  box-sizing: border-box;
  color: #444;
}

.interactive-piano__natural-key--playing {
  background-color: #91e537;
  border: 3px solid #86db2b;
  border-top: none;
}

.interactive-piano__natural-key:first-of-type {
  margin-left: 0;
}

.interactive-piano__natural-key:last-of-type {
  margin-right: 0;
}

.interactive-piano__text {
  pointer-events: none;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 1rem;
}