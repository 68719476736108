@import "../theme/_theme";

.beat-search {
}
.sequence {
  width: 100%;
  height: 100%;
  display: flex;
  /*   align-items: center;
  justify-content: center; */
  @include themify($themes) {
    background-color: themed("audioBox");
  }
  // padding: 10px;
}
.bits-controls {
  /*   width: 25%;
  height: 100%; */
  font-size: 11px;
  /* padding-top: 20px; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 1px 8px;
}
.beat-type,
.beat-search-btn {
  margin: 3px 0 12px 0;
}
.bits-controls .beat-type,
.bits-controls .beat-search-btn {
  width: 100px;
  height: 20px;
  background-color: #aaa8a8;
  @include themify($themes) {
    color: themed("textColor");
  }
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
}
.objects {
  display: flex;
  align-items: center;
  justify-content: center;
  row-gap: 3px;
  column-gap: 3px;
  width: 75%;
  height: 100%;
}
.search {
  display: flex;
  align-items: center;
  justify-self: start;
}
.text-input {
  width: 400px;
  height: 30px;
  border: 1px solid rgb(190, 190, 190);
  border-radius: 3px;
  outline: none;
  text-align: center;
  font-size: 1em;
}
.search-btn {
  width: 50px;
  height: 30px;
  background-color: #1aa1e1;
  color: white;
  border: 0;
  border-radius: 3px;
  margin-left: 5px;
  cursor: pointer;
}
.search-btn:hover {
  background-color: #0075ac;
}
.search-toggle {
  background-color: #1aa1e1;
  color: #fff;
  width: 50%;
  height: 100%;
  border: 0;
  font-size: 1em;
  transition: all 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-50%);
}
.search-toggle.on {
  transform: translateX(50%);
}
button.bit-change {
  background-color: transparent;
  width: 50%;
  height: 100%;
  color: #000000;
  border: 0;
  font-size: 1em;
  transition: all 0.3s;
  cursor: pointer;
}
button.bit-change.selected {
  background-color: #1aa1e1;
  color: #fff;
}
.find-btn {
  outline: none;
  width: 70%;
  height: 20px;
  background-color: #1aa1e1;
  border: 1px solid #0c85c5;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}
.find-btn:hover {
  background-color: #1e83b3;
}
