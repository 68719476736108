@import "../theme/_theme";

.track {
  // width: 99%;
  height: 190px;
  border: 1px solid #ededed;
  border-radius: 30px;
  // display: flex;
  align-items: center;
  justify-content: center;
  // overflow: hidden;
  @include themify($themes) {
    background-color: themed("audioBox");
  }
  margin: 20px;
  //position: relative;
}

.remove-btn {
  position: relative;
  margin-top: 5px;
  margin-left: 5px;
  background-color: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
  @include themify($themes) {
    color: themed("textColor");
  }
}
.remove-btn img {
  width: 15px;
  height: 15px;
}

.category {
  height: 100%;
  margin: 20px;
  span {
    font-size: 12px;
  }
  input {
    height: 10px;
    width: 10px;
    margin: 8px 4px;
  }
  label {
    font-size: 11px;
    margin: 8px 4px;
  }
  select {
    margin: 8px 4px;
  }
}

.beat-search {
  margin: 20px;
  span {
    font-size: 12px;
  }
}

.controls {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  span {
    font-size: 12px;
  }
}

.vol-slider {
  width: 100%;
}
.seek-controls {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.seek-controls p {
  position: absolute;
}
.seek-controls p:nth-child(1) {
  bottom: 0;
  left: 0;
}
.seek-controls p:nth-child(2) {
  bottom: 0;
  right: 0;
}
.currentId {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
}
.mute-btn,
.seek-btn {
  outline: none;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  .MuiSvgIcon-root {
    width: 20px !important;
    height: 20px !important;
  }
  @include themify($themes) {
    color: themed("textColor");
  }
}
.seek-btn {
  margin-right: 30px;
}
.mute-btn img,
.seek-btn img {
  width: 70px;
  height: 70px;
}

.track_inner {
  border-radius: 10px;
  border: 1px solid #aaa8a8;
  @include themify($themes) {
    color: themed("textColor");
    background-color: themed("audioBox");
  }
  margin: 0px 70px;
}
/* 
.track_inner {
  display: flex;
}

.category,
.beat-search,
.controls {
  position: relative;
}
.controls .volume-label {
  position: absolute;
  top: 20px;
  left: 250px;
}
.category span,
.beat-search span,
.controls span {
  position: absolute;
  left: 5px;
  top: 5px;
  font-weight: 700;
  color: #596780;
  opacity: 0.9;
}
.category {
  width: 11.5%;
  height: 100%;
  @include themify($themes) {
    background-color: themed("audioBox");
  }
  padding: 35px 30px 0px 10px;
}
.category input {
  margin-bottom: 20px;
}
.category select {
  width: 100%;
  outline: none;
  height: 25px;
  border: 1px solid #ececec;
  border-radius: 5px;
  color: #596780;
}
.beat-search {
  width: 40%;
  height: 100%;
}
.controls {
  width: 37.5%;
  height: 100%;
  @include themify($themes) {
    background-color: themed("audioBox");
  }
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.controls > div:nth-child(2) {
  width: 77%;
  height: 100%;
  padding: 30px 10px 0 10px;
}
.controls > div:nth-child(3) {
  width: 23%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.scale {
  transform: scale(1.2);
}
.track p {
  font-size: 0.7em;
}
 */
