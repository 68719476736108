@font-face {
  font-family: "feather";
  src: url("fonts/feather.eot?9xfrq8");
  src: url("fonts/feather.eot?9xfrq8#iefix") format("embedded-opentype"),
    url("fonts/feather.ttf?9xfrq8") format("truetype"),
    url("fonts/feather.woff?9xfrq8") format("woff"),
    url("fonts/feather.svg?9xfrq8#feather") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="ft-"],
[class*=" ft-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "feather" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ft-bold:before {
  content: "\e9ea";
}

.ft-crop:before {
  content: "\e9eb";
}

.ft-help-circle:before {
  content: "\e9ec";
}

.ft-italic:before {
  content: "\e9ed";
}

.ft-shopping-cart:before {
  content: "\e9ee";
}

.ft-underline:before {
  content: "\e9ef";
}

.ft-activity:before {
  content: "\e900";
}

.ft-airplay:before {
  content: "\e901";
}

.ft-alert-circle:before {
  content: "\e902";
}

.ft-alert-octagon:before {
  content: "\e903";
}

.ft-alert-triangle:before {
  content: "\e904";
}

.ft-align-center:before {
  content: "\e905";
}

.ft-align-justify:before {
  content: "\e906";
}

.ft-align-left:before {
  content: "\e907";
}

.ft-align-right:before {
  content: "\e908";
}

.ft-anchor:before {
  content: "\e909";
}

.ft-aperture:before {
  content: "\e90a";
}

.ft-arrow-down-left:before {
  content: "\e90b";
}

.ft-arrow-down-right:before {
  content: "\e90c";
}

.ft-arrow-down:before {
  content: "\e90d";
}

.ft-arrow-left:before {
  content: "\e90e";
}

.ft-arrow-right:before {
  content: "\e90f";
}

.ft-arrow-up-left:before {
  content: "\e910";
}

.ft-arrow-up-right:before {
  content: "\e911";
}

.ft-arrow-up:before {
  content: "\e912";
}

.ft-at-sign:before {
  content: "\e913";
}

.ft-award:before {
  content: "\e914";
}

.ft-bar-chart-2:before {
  content: "\e915";
}

.ft-bar-chart:before {
  content: "\e916";
}

.ft-battery-charging:before {
  content: "\e917";
}

.ft-battery:before {
  content: "\e918";
}

.ft-bell-off:before {
  content: "\e919";
}

.ft-bell:before {
  content: "\e91a";
}

.ft-bluetooth:before {
  content: "\e91b";
}

.ft-book:before {
  content: "\e91c";
}

.ft-bookmark:before {
  content: "\e91d";
}

.ft-box:before {
  content: "\e91e";
}

.ft-briefcase:before {
  content: "\e91f";
}

.ft-calendar:before {
  content: "\e920";
}

.ft-camera-off:before {
  content: "\e921";
}

.ft-camera:before {
  content: "\e922";
}

.ft-cast:before {
  content: "\e923";
}

.ft-check-circle:before {
  content: "\e924";
}

.ft-check-square:before {
  content: "\e925";
}

.ft-check:before {
  content: "\e926";
}

.ft-chevron-down:before {
  content: "\e927";
}

.ft-chevron-left:before {
  content: "\e928";
}

.ft-chevron-right:before {
  content: "\e929";
}

.ft-chevron-up:before {
  content: "\e92a";
}

.ft-chevrons-down:before {
  content: "\e92b";
}

.ft-chevrons-left:before {
  content: "\e92c";
}

.ft-chevrons-right:before {
  content: "\e92d";
}

.ft-chevrons-up:before {
  content: "\e92e";
}

.ft-chrome:before {
  content: "\e92f";
}

.ft-circle:before {
  content: "\e930";
}

.ft-clipboard:before {
  content: "\e931";
}

.ft-clock:before {
  content: "\e932";
}

.ft-cloud-drizzle:before {
  content: "\e933";
}

.ft-cloud-lightning:before {
  content: "\e934";
}

.ft-cloud-off:before {
  content: "\e935";
}

.ft-cloud-rain:before {
  content: "\e936";
}

.ft-cloud-snow:before {
  content: "\e937";
}

.ft-cloud:before {
  content: "\e938";
}

.ft-codepen:before {
  content: "\e939";
}

.ft-command:before {
  content: "\e93a";
}

.ft-compass:before {
  content: "\e93b";
}

.ft-copy:before {
  content: "\e93c";
}

.ft-corner-down-left:before {
  content: "\e93d";
}

.ft-corner-down-right:before {
  content: "\e93e";
}

.ft-corner-left-down:before {
  content: "\e93f";
}

.ft-corner-left-up:before {
  content: "\e940";
}

.ft-corner-right-down:before {
  content: "\e941";
}

.ft-corner-right-up:before {
  content: "\e942";
}

.ft-corner-up-left:before {
  content: "\e943";
}

.ft-corner-up-right:before {
  content: "\e944";
}

.ft-cpu:before {
  content: "\e945";
}

.ft-credit-card:before {
  content: "\e946";
}

.ft-crosshair:before {
  content: "\e947";
}

.ft-delete:before {
  content: "\e948";
}

.ft-disc:before {
  content: "\e949";
}

.ft-download-cloud:before {
  content: "\e94a";
}

.ft-download:before {
  content: "\e94b";
}

.ft-droplet:before {
  content: "\e94c";
}

.ft-edit-2:before {
  content: "\e94d";
}

.ft-edit-3:before {
  content: "\e94e";
}

.ft-edit:before {
  content: "\e94f";
}

.ft-external-link:before {
  content: "\e950";
}

.ft-eye-off:before {
  content: "\e951";
}

.ft-eye:before {
  content: "\e952";
}

.ft-facebook:before {
  content: "\e953";
}

.ft-fast-forward:before {
  content: "\e954";
}

.ft-feather:before {
  content: "\e955";
}

.ft-file-minus:before {
  content: "\e956";
}

.ft-file-plus:before {
  content: "\e957";
}

.ft-file-text:before {
  content: "\e958";
}

.ft-file:before {
  content: "\e959";
}

.ft-film:before {
  content: "\e95a";
}

.ft-filter:before {
  content: "\e95b";
}

.ft-flag:before {
  content: "\e95c";
}

.ft-folder:before {
  content: "\e95d";
}

.ft-github:before {
  content: "\e95e";
}

.ft-gitlab:before {
  content: "\e95f";
}

.ft-globe:before {
  content: "\e960";
}

.ft-grid:before {
  content: "\e961";
}

.ft-hash:before {
  content: "\e962";
}

.ft-headphones:before {
  content: "\e963";
}

.ft-heart:before {
  content: "\e964";
}

.ft-home:before {
  content: "\e965";
}

.ft-image:before {
  content: "\e966";
}

.ft-inbox:before {
  content: "\e967";
}

.ft-info:before {
  content: "\e968";
}

.ft-instagram:before {
  content: "\e969";
}

.ft-layers:before {
  content: "\e96a";
}

.ft-layout:before {
  content: "\e96b";
}

.ft-life-buoy:before {
  content: "\e96c";
}

.ft-link-2:before {
  content: "\e96d";
}

.ft-link:before {
  content: "\e96e";
}

.ft-list:before {
  content: "\e96f";
}

.ft-loader:before {
  content: "\e970";
}

.ft-lock:before {
  content: "\e971";
}

.ft-log-in:before {
  content: "\e972";
}

.ft-log-out:before {
  content: "\e973";
}

.ft-mail:before {
  content: "\e974";
}

.ft-map-pin:before {
  content: "\e975";
}

.ft-map:before {
  content: "\e976";
}

.ft-maximize-2:before {
  content: "\e977";
}

.ft-maximize:before {
  content: "\e978";
}

.ft-menu:before {
  content: "\e979";
}

.ft-message-circle:before {
  content: "\e97a";
}

.ft-message-square:before {
  content: "\e97b";
}

.ft-mic-off:before {
  content: "\e97c";
}

.ft-mic:before {
  content: "\e97d";
}

.ft-minimize-2:before {
  content: "\e97e";
}

.ft-minimize:before {
  content: "\e97f";
}

.ft-minus-circle:before {
  content: "\e980";
}

.ft-minus-square:before {
  content: "\e981";
}

.ft-minus:before {
  content: "\e982";
}

.ft-monitor:before {
  content: "\e983";
}

.ft-moon:before {
  content: "\e984";
}

.ft-more-horizontal:before {
  content: "\e985";
}

.ft-more-vertical:before {
  content: "\e986";
}

.ft-move:before {
  content: "\e987";
}

.ft-music:before {
  content: "\e988";
}

.ft-navigation-2:before {
  content: "\e989";
}

.ft-navigation:before {
  content: "\e98a";
}

.ft-octagon:before {
  content: "\e98b";
}

.ft-package:before {
  content: "\e98c";
}

.ft-paperclip:before {
  content: "\e98d";
}

.ft-pause-circle:before {
  content: "\e98e";
}

.ft-pause:before {
  content: "\e98f";
}

.ft-percent:before {
  content: "\e990";
}

.ft-phone-call:before {
  content: "\e991";
}

.ft-phone-forwarded:before {
  content: "\e992";
}

.ft-phone-incoming:before {
  content: "\e993";
}

.ft-phone-missed:before {
  content: "\e994";
}

.ft-phone-off:before {
  content: "\e995";
}

.ft-phone-outgoing:before {
  content: "\e996";
}

.ft-phone:before {
  content: "\e997";
}

.ft-pie-chart:before {
  content: "\e998";
}

.ft-play-circle:before {
  content: "\e999";
}

.ft-play:before {
  content: "\e99a";
}

.ft-plus-circle:before {
  content: "\e99b";
}

.ft-plus-square:before {
  content: "\e99c";
}

.ft-plus:before {
  content: "\e99d";
}

.ft-pocket:before {
  content: "\e99e";
}

.ft-power:before {
  content: "\e99f";
}

.ft-printer:before {
  content: "\e9a0";
}

.ft-radio:before {
  content: "\e9a1";
}

.ft-refresh-ccw:before {
  content: "\e9a2";
}

.ft-refresh-cw:before {
  content: "\e9a3";
}

.ft-repeat:before {
  content: "\e9a4";
}

.ft-rewind:before {
  content: "\e9a5";
}

.ft-rotate-ccw:before {
  content: "\e9a6";
}

.ft-rotate-cw:before {
  content: "\e9a7";
}

.ft-save:before {
  content: "\e9a8";
}

.ft-scissors:before {
  content: "\e9a9";
}

.ft-search:before {
  content: "\e9aa";
}

.ft-server:before {
  content: "\e9ab";
}

.ft-settings:before {
  content: "\e9ac";
}

.ft-share-2:before {
  content: "\e9ad";
}

.ft-share:before {
  content: "\e9ae";
}

.ft-shield:before {
  content: "\e9af";
}

.ft-shuffle:before {
  content: "\e9b0";
}

.ft-sidebar:before {
  content: "\e9b1";
}

.ft-skip-back:before {
  content: "\e9b2";
}

.ft-skip-forward:before {
  content: "\e9b3";
}

.ft-slack:before {
  content: "\e9b4";
}

.ft-slash:before {
  content: "\e9b5";
}

.ft-sliders:before {
  content: "\e9b6";
}

.ft-smartphone:before {
  content: "\e9b7";
}

.ft-speaker:before {
  content: "\e9b8";
}

.ft-square:before {
  content: "\e9b9";
}

.ft-star:before {
  content: "\e9ba";
}

.ft-stop-circle:before {
  content: "\e9bb";
}

.ft-sun:before {
  content: "\e9bc";
}

.ft-sunrise:before {
  content: "\e9bd";
}

.ft-sunset:before {
  content: "\e9be";
}

.ft-tablet:before {
  content: "\e9bf";
}

.ft-tag:before {
  content: "\e9c0";
}

.ft-target:before {
  content: "\e9c1";
}

.ft-thermometer:before {
  content: "\e9c2";
}

.ft-thumbs-down:before {
  content: "\e9c3";
}

.ft-thumbs-up:before {
  content: "\e9c4";
}

.ft-toggle-left:before {
  content: "\e9c5";
}

.ft-toggle-right:before {
  content: "\e9c6";
}

.ft-trash-2:before {
  content: "\e9c7";
}

.ft-trash:before {
  content: "\e9c8";
}

.ft-trending-down:before {
  content: "\e9c9";
}

.ft-trending-up:before {
  content: "\e9ca";
}

.ft-triangle:before {
  content: "\e9cb";
}

.ft-tv:before {
  content: "\e9cc";
}

.ft-twitter:before {
  content: "\e9cd";
}

.ft-type:before {
  content: "\e9ce";
}

.ft-umbrella:before {
  content: "\e9cf";
}

.ft-unlock:before {
  content: "\e9d0";
}

.ft-upload-cloud:before {
  content: "\e9d1";
}

.ft-upload:before {
  content: "\e9d2";
}

.ft-user-check:before {
  content: "\e9d3";
}

.ft-user-minus:before {
  content: "\e9d4";
}

.ft-user-plus:before {
  content: "\e9d5";
}

.ft-user-x:before {
  content: "\e9d6";
}

.ft-user:before {
  content: "\e9d7";
}

.ft-users:before {
  content: "\e9d8";
}

.ft-video-off:before {
  content: "\e9d9";
}

.ft-video:before {
  content: "\e9da";
}

.ft-voicemail:before {
  content: "\e9db";
}

.ft-volume-1:before {
  content: "\e9dc";
}

.ft-volume-2:before {
  content: "\e9dd";
}

.ft-volume-x:before {
  content: "\e9de";
}

.ft-volume:before {
  content: "\e9df";
}

.ft-watch:before {
  content: "\e9e0";
}

.ft-wifi-off:before {
  content: "\e9e1";
}

.ft-wifi:before {
  content: "\e9e2";
}

.ft-wind:before {
  content: "\e9e3";
}

.ft-x-circle:before {
  content: "\e9e4";
}

.ft-x-square:before {
  content: "\e9e5";
}

.ft-x:before {
  content: "\e9e6";
}

.ft-zap:before {
  content: "\e9e7";
}

.ft-zoom-in:before {
  content: "\e9e8";
}

.ft-zoom-out:before {
  content: "\e9e9";
}

.newHeader a {
  font-size: 14.7px !important;
  color: #516574 !important;
  outline: none;
  margin-left: 10px;
  text-decoration: none !important;
}

.newHeader a:hover {
  color: #818a91 !important;
}

.newHeader .nav-item i {
  margin-right: 0.2rem !important;
}

.newHeader .MuiButton-root {
  color: #516574 !important;
  font-size: 14.7px !important;
  text-transform: none !important;
}

.newHeader .AccountUserInfo {
  margin-left: auto !important;
}

.accountUser {
  color: #516574;
  text-decoration: none
}

.dropdownItem {
  width: 100%;
  text-decoration: none;
  color: #516574
}