@import "../theme/_theme";

.samples {
  width: 100%;
  height: 100vh;
}
.container-tracker {
  height: 80%;
  overflow: auto;
  @include themify($themes) {
    background-color: themed("backgroundColor");
  }
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  .tracks {
    width: 100%;
  }
  .track {
    font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
      "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
.add-track {
  transition: 0.4s all;
  margin: 30px 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
  @include themify($themes) {
    color: themed("textColor");
  }
}
