@import "../theme/_theme";

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  border-radius: 100px;
  @include themify($themes) {
    color: themed("textColor");
    background-color: themed("backgroundColor");
  }
  input {
    display: none;
    border-radius: 100px;
    @include themify($themes) {
      color: themed("textColor");
      background-color: themed("backgroundColor");
    }
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 100px;

  &:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 100px;
  }
}

input {
  &:checked + .slider {
    background-color: #2196f3;
  }

  &:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  &:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
}
