@import "../theme/_theme";

/* .request-menu {
  background-color: #2c2c2c;
  display: flex;
} */
.inner-request-menu {
  width: 100%;
  padding-bottom: 10px;
  height: 12%;
}
.editors-section {
  @include themify($themes) {
    background-color: themed("backgroundColor");
  }
  display: flex;
  width: 100%;
}
.outter-editors {
  display: flex;
}
.Panel-above-editors {
  @include themify($themes) {
    background-color: themed("backgroundColor");
  }
  display: flex;
  height: 40px;
  font-family: RobotoMono-Regular;
  border-top: 1px solid #aaa8a8;
  border-bottom: 1px solid #aaa8a8;
}
.panel-inner {
  width: 100%;
  @include themify($themes) {
    background-color: themed("backgroundColor");
  }
}
.panel-inner-above {
  display: flex;
  position: absolute;
  right: 10%;
}
.panel-inner-above div {
  width: fit-content;
}
.Panel-inner-editors {
  @include themify($themes) {
    color: themed("textColor");
  }
  margin-left: 10px;
}
.inner-editors {
  @include themify($themes) {
    background-color: themed("backgroundColor");
  }
  width: 100%;
  overflow-x: hidden;
  padding-bottom: 10px;
}

.menu-header {
  @include themify($themes) {
    color: themed("textColor");
  }
  height: 70px;
  padding: 10px 0px 10px 4px;
  width: 100%;
}
.second-coloumn {
  width: 100%;
}
.header-left {
  width: 100%;
  @include themify($themes) {
    background-color: themed("backgroundColor");
  }
  color: white;
  border-bottom: 1px solid #aaa8a8;
  border-top: 1px solid #aaa8a8;
}
.devTool {
  background-color: #6346b9;
  color: white;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  border: none;
  margin: 4px;
}

.icon {
  background-color: #6346b9;
  border: none;
  color: white;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  opacity: 1;
  margin: 6px;
}
.aceEditors {
  display: flex;
}

.two_show {
  display: none;
}

.ButtonSectionAssets {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0.7rem;
  .asset_btn {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: none;
    margin-right: 0.5rem;
  }
  .asset_btn_ops {
    width: 46px;
    height: 46px;
    border: none;
    border-radius: 100%;
    margin-right: 0.5rem;
  }
  .btn_midi {
    background-color: #fcb0b5;
  }
  .btn_plugin {
    background-color: #ffefc4;
  }
  .btn_patch {
    background-color: #b5e2fc;
  }
}

.left-column-wrapper {
  width: 100%;
  height: 100%;
  border: 1px solid #aaa8a8;
  @include themify($themes) {
    background-color: themed("backgroundColor");
  }
}
