.filter-asset-bpm, .filter-asset-category, .filter-asset-signatures, .filter-asset-search{
  .filter-asset-list {
    display: flex;
    flex-wrap: wrap;
  }
  hr {
    margin: 5px 0px 5px;
  }
  .filter-asset-item {
    padding: 2px 10px;
    border: 1px solid #676767;
    text-align: center;
    border-radius: 5px;
    margin-right: 10px;
    margin: 5px 10px 5px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.active {
      border-color: #89a5ce;
      background-color: #dbe8fc;
      color: #000000;
      font-weight: 600;
    }
    .filter-asset-item-quality {
      margin-left: 10px;
      width: 25px;
      height: 25px;
      background: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
  }
}
.filter-asset-category, .filter-asset-signatures{
  margin-top: 40px
}

.filter-asset-search {
  margin: 40px 0px
}