#jwt_textarea {
  width: 100%;
  height: 90vh;
  padding: 20px;
  margin: 10px;
  resize: none;
  font-size: 12px;
  cursor: auto;
  border: none;
  border-radius: 6px 6px 6px 6px;
  box-shadow: 0 1px 2px rgb(249 0 0 / 39%) inset;
}
.jwt_encoded {
  color: #00b9f1;
}
.jwt_decoded {
  color: #d63aff;
}
@media (max-width: 600px) {
  #jwt_textarea {
    width: 100%;
  }
}
