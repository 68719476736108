.bootstrap__table {
  width: 80%;
  margin: auto;
}

.addButton__wrapper {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  padding: 20px 0px;
}
