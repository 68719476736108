$themes: (
  // Left-Right
  light-ltr:
    (
      logoTextColor: #ff69a5,
      backgroundColor: white,
      textColor: #000000,
      buttonTextColor: #408bbd,
      buttonTextTransform: none,
      buttonTextHoverColor: #61b0e7,
      buttonColor: #fff,
      buttonBorder: 2px solid #408bbd,
      directionContent: ltr,
      floatContent: left,
      dashboardRightContent: #efefef,
      audioBox: #fff,
    ),
  dark-ltr: (
    logoTextColor: #ff69a5,
    backgroundColor: #2C2C2C,
    textColor: #fff,
    buttonTextColor: #aaa,
    buttonTextTransform: uppercase,
    buttonTextHoverColor: #ddd,
    buttonColor: #333,
    buttonBorder: 1px solid #333,
    directionContent: ltr,
    floatContent: left,
    dashboardRightContent: #535a69,
    audioBox: #333333,
  ),
  // Right-Left
  light-rtl:
    (
      logoTextColor: #ff69a5,
      backgroundColor: white,
      textColor: #000000,
      buttonTextColor: #408bbd,
      buttonTextTransform: none,
      buttonTextHoverColor: #61b0e7,
      buttonColor: #fff,
      buttonBorder: 2px solid #408bbd,
      directionContent: rtl,
      floatContent: right,
      dashboardRightContent: #efefef,
      audioBox: #fff,
    ),
  dark-rtl: (
    logoTextColor: #ff69a5,
    backgroundColor: #2C2C2C,
    textColor: #fff,
    buttonTextColor: #aaa,
    buttonTextTransform: uppercase,
    buttonTextHoverColor: #ddd,
    buttonColor: #333,
    buttonBorder: 1px solid #333,
    directionContent: rtl,
    floatContent: right,
    dashboardRightContent: #535a69,
    audioBox: #333333,
  )
);

/*
 * Implementation of themes
 */
@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}
