.nav-dropdown-text {
    display: flex;
    text-decoration: none !important;
  }
  .nav-dropdown-text a {
    text-decoration: none !important;
  }
  .nav-dropdown-textimg {
    padding-left: 0px;
    width: 15px;
    height: 15px;
    padding-top: 10px;
  }
  
  .nav-dropdown-inner {
    width: 80% !important;
  }
  .nav-dropdown-outter {
    top: 45px;
  }
  
  .nav-dropdown-inner-list {
  }
  