.midibits {
  height: 100%;
}
.bitContainer {
  width: 50px;
  /* height: 87%; */
  background-color: #1aa1e1;
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  border: 1px solid #0a73b2;
  position: relative;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: column;
  padding: 30px 5px;
}
.value {
  color: black;
  font-size: 10px;
  width: 50px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e2e4e6;
}
.bit {
  width: 10px;
  height: 10px;
  margin: 2px;
  border-radius: 50%;
  border: 1px solid white;
  cursor: pointer;
}
.bit.selected {
  border: 2px solid orange;
}
.bit.set {
  background-color: white;
}
.bit.unset {
  background-color: #1aa1e1;
}
.disable {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(128, 128, 128, 0.301);
  z-index: 99;
}
.disable.show {
  display: block;
}
.disable.hide {
  display: none;
}
.bitContainer.threeDots {
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: column;
}
